import {Game} from "./components/Game";
import './App.css';
function App() {
    return (
        <>
           <Game/>
        </>

    )
}

export default App;
